import React from 'react';
import { Link } from 'gatsby';

const Features = props => {
  const features = props.features;

  return (
    <div className="features mt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-6">
            <h1 className="text-center">Gain Access Over the Customer Experience</h1>
          </div>
        </div>

        {features.length > 0 && (
          <div className="row justify-content-center mt-3">
            {features.map(({ node }) => (
              <div key={node.id} className="shadow-sm col-12 col-md-4 mb-2">
                <div className="feature">
                  {node.image && (
                    <div className="feature-image">
                      <img alt={node.title} src={node.image} />
                    </div>
                  )}
                  <h3 className="my-3 align-self-center">{node.title}</h3>
                  <p className="align-self-center feature-description">{node.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="row justify-content-center mt-3">
          <div className="col-12 align-self-center text-center">
            <Link to="#contact_us">
              <button type="button" className="px-3 py-1 text-white btn btn-primary-button rounded-pill">
                Book Demo
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
