import React from 'react';
import { Link } from 'gatsby';

const Intro = props => {
  const intro = props.intro;
  const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute && 'intro-image-absolute'}
    ${intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'}`;

  return (
    <div className="intro">
      <div className="container">
        <div className="row justify-content-start">
          <div className="col-12 col-lg-4 order-2 order-lg-1 text-center text-lg-left">
            <h1>CUSTOMER ENGAGEMENT REIMAGINED</h1>
            <p>Sales automation comes to the automotive retail industry</p>
            <Link to="#features">
              <button type="button" className="px-3 py-1 text-white btn btn-primary-button rounded-pill">
                Learn More
              </button>
            </Link>
          </div>
          <div className="col-12 col-lg-8 order-1 order-lg-2 position-relative">
            <img alt={intro.frontmatter.title} className={introImageClasses} src={intro.frontmatter.intro_image} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
