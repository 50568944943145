import React from 'react';

const Mobile = props => (
  <div className="instant-comm mt-7">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <p className=" text-primary">AI Guided and AI Enhanced</p>
          <h1>Evolution of real-time messaging</h1>
        </div>
      </div>
      <div className="row justify-content-start mt-5">
        <div className="col">
          <img className="img-responsive" alt="mobile-screenshot" src="images/mobile-comm/phone-1.png" />
        </div>
        <div className="col-1-md" />
        <div className="col">
          <img className="img-responsive" alt="mobile-screenshot" src="images/mobile-comm/phone-2.png" />
        </div>
        <div className="col-1-md" />
        <div className="col">
          <img className="img-responsive" alt="mobile-screenshot" src="images/mobile-comm/phone-3.png" />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-9">
          <p className="text-center">
            The process starts just as the sales lead is en route. KarFarm’s AI enhanced platform guides sales teams to optimize customer retention and maximize
            conversion rate
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Mobile;
