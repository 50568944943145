import React from 'react';

const Dashboard = props => (
  <div className="dashboard mt-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-6">
          <h1 className="text-center">Modernize your workflow and maximize result</h1>
        </div>
      </div>
      <div className="row justify-content-start mt-3">
        <div className="col position-relative vertical-align-middle">
          <img alt="mobile-screenshot" src="images/dashboard/dashboard.png" className="w-100" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-9">
          <p className="text-center">KarFarm’s AI enhanced platform guides sales teams to optimize customers retention and maximize conversion rate</p>
        </div>
      </div>
    </div>
  </div>
);

export default Dashboard;
