import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';
import Intro from '../components/Intro';
import Mobile from '../components/Mobile';
import Dashboard from '../components/Dashboard';
import Features from '../components/Features';
import AboutUs from '../components/AboutUs';
import ContactUs from '../components/ContactUs';

const Home = props => {
  const intro = props.data.intro;
  const site = props.data.site.siteMetadata;
  const features = props.data.features.edges;

  return (
    <Layout bodyClass="page-home">
      <SEO title={site.title} />
      <Helmet>
        <meta name="description" content="Sales automation comes to the automotive retail industry" />
      </Helmet>

      <Intro intro={intro} />
      <div id="features" />
      <Mobile />
      <Dashboard />
      <Features features={features} />
      <div id="about_us" />
      <AboutUs />
      <ContactUs />
    </Layout>
  );
};

export const query = graphql`
  query {
    intro: markdownRemark(fileAbsolutePath: { regex: "/content/index.md/" }) {
      html
      frontmatter {
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
    features: allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
